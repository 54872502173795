$light-green-color: #e6ee9c;
$green-color: #cddc39;
$green-stats: #7cb342;
$progress-success-color: #88B153;

$progress-todo-color: #bfbfbf;
$progress-announce-color: #64c6a2;

$green-client-a: #7cb342;
$green-client-b: #cddc39;
$green-client-c: #e6ee9c;

$ultra-light-yellow-color: #ffecb5;
$light-yellow-color: #ffe082;
$yellow-color: #ffc107;

$secondary-grey: #afafaf;
$text-grey: #7e7e7e;
$grey-background: #efefef;

$field-red-color: #EFC3C3;
$light-red-color: #ff867c;
$orange-stats: #ef6c00;
$alert-color: #FF5722;
$progress-fail-color: #D50000;
$progress-rejected-color: #FF5722;
$progress-inprogress-color: #FFC107;


$link-color: #3939ba;

$blue-light: #E3F2FD;
$blue-nice: #039be5;

$black: #000000;
$primary-color: #212121;
$accent-color: #ffc107;
